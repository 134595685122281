import {
  cardTitle,
  container,
  description,
  mlAuto,
  mrAuto,
  section,
  textCenter,
  title,
} from "assets/jss/material-kit-pro-react.jsx"

const admissionStyle = (theme) => ({
  admissionDivider: {
    width: "10%",
    border: "3px solid",
  },
  admissionItem: {
    ...mlAuto,
    ...mrAuto,
    ...textCenter,
  },
  cardGeneralPrice: {
    ...cardTitle,
    fontSize: "1.5rem",
  },
  cardHeader: {
    ...cardTitle,
    fontWeight: "600 !important",
    fontSize: "1.5rem",
    marginBottom: "20px",
    marginTop: "5px",
  },
  cardInfo: {
    ...cardTitle,
  },
  cardVipPrice: {
    ...cardTitle,
    fontSize: "1.25rem",
  },
  container: {
    ...container,
    [theme.breakpoints.down("sm")]: {
      maxWidth: "650px",
    },
  },
  customIcon: {
    height: "55px",
    width: "auto",
    margin: "10px",
  },
  description,
  externalLink: {
    color: "#777",
    "&:hover": {
      color: "#6d91e4",
    },
  },
  justifyContentCenter: {
    WebkitBoxPack: "center !important",
    MsFlexPack: "center !important",
    justifyContent: "center !important",
  },
  section: {
    ...section,
    padding: "40px 0",
    position: "relative",
    "& $container": {
      position: "relative",
      zIndex: "2",
    },
    "& $title": {
      marginBottom: "10px",
    },
  },
  smallDescription: {
    color: "#888",
    fontSize: ".85rem",
    fontWeight: "400",
  },
  ticketsAlign: {
    position: "absolute",
    zIndex: "-1",
    left: "-2.5%",
    width: "50%",
    top: "10%",
    opacity: ".4",
    [theme.breakpoints.down("sm")]: {
      opacity: ".2",
    },
  },
  ticketButton: {
    margin: "1rem 1rem 3rem 1rem",
    lineHeight: "18px",
  },
  ticketCard: {
    border: "3px solid #377358",
    boxShadow: "none",
    transition: "all .2s ease-in-out",
    "&:hover": {
      boxShadow: "0px 10px 20px 0px rgba(0, 0, 0, 0.3)",
    },
  },
  title,
})

export default admissionStyle
