import Hidden from "@material-ui/core/Hidden"
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"
// page images
import chiliPepper from "assets/img/icons/chili-pepper.svg"
import cowboyHat from "assets/img/icons/cowboy-hat.svg"
import ticketsIconBackground from "assets/img/ticket.svg"
// page styles
import admissionStyle from "assets/jss/material-kit-pro-react/views/landingPageSections/admissionStyle.jsx"
// core components
import Card from "components/Card/Card.jsx"
import CardBody from "components/Card/CardBody.jsx"
import CardFooter from "components/Card/CardFooter.jsx"
import GridContainer from "components/Grid/GridContainer.jsx"
import GridItem from "components/Grid/GridItem.jsx"
import React from "react"
// react libraries
import Slide from "react-reveal/Slide"

const useStyles = makeStyles(admissionStyle)

export default function SectionAdmission() {
  const classes = useStyles()
  return (
    <div className={classes.section} id="admission">
      <div className={classes.container}>
        <GridContainer>
          <GridItem xs={12} sm={10} md={8} className={classes.admissionItem}>
            <h2 className={classes.title}>Admission Prices &amp; Tickets</h2>
            <h5 className={classes.description}>
              All proceeds benefit the Chilimania Scholarship Fund and other
              community-based projects and organizations. Scroll below for
              admissions information and tickets.
            </h5>
            <p className={classes.smallDescription}>
              <b>Note:</b> Country Edge and Chilimania are separate events and
              require separate admission tickets.
            </p>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <img
            className={classes.ticketsAlign}
            src={ticketsIconBackground}
            alt="Tickets Icon"
          />
          <GridItem md={4} implementation="css" smDown component={Hidden} />
          <GridItem xs={12} sm={6} md={4}>
            <Slide right>
              <Card className={classes.ticketCard} pricing>
                <CardBody pricing>
                  <img
                    className={classes.customIcon}
                    src={cowboyHat}
                    alt={"Cowboy Hat Icon"}
                  />
                  <h3 className={classes.cardHeader}>COUNTRY EDGE</h3>
                  <h5 className={classes.cardInfo}>General Admission</h5>
                  <p className={classes.cardGeneralPrice}>$30</p>
                  <hr width={"80%"} />
                  <p className={classes.cardInfo}>Children 10 &amp; Under</p>
                  <p>Free with Adult</p>
                  <p>Applies to general admission only!</p>
                  <hr width={"80%"} />
                  <p className={classes.cardInfo}>Dedicated VIP Area</p>
                  <h5 className={classes.cardVipPrice}>$100</h5>
                  <hr width={"80%"} />
                  <p className={classes.cardInfo}>
                    Tickets will be available at the gate for $35.
                    <br />
                    Pre-sale tickets available below.
                  </p>
                </CardBody>
                <CardFooter className={classes.justifyContentCenter} pricing>
                  <p>*Processing fees may apply</p>
                </CardFooter>
              </Card>
            </Slide>
          </GridItem>
          <GridItem xs={12} sm={6} md={4} className={classes.mrAuto}>
            <Slide right delay={500}>
              <Card className={classes.ticketCard} pricing>
                <CardBody pricing>
                  <img
                    className={classes.customIcon}
                    src={chiliPepper}
                    alt={"Chili Pepper Icon"}
                  />
                  <h3 className={classes.cardHeader}>CHILIMANIA</h3>
                  <h5 className={classes.cardInfo}>General Admission</h5>
                  <p className={classes.cardGeneralPrice}>$15</p>
                  <hr width={"80%"} />
                  <p className={classes.cardInfo}>Children 10 &amp; Under</p>
                  <p>Free with Adult</p>
                  <p>Applies to general admission only!</p>
                  <hr width={"80%"} />
                  <p className={classes.cardInfo}>Dedicated VIP Area</p>
                  <h5 className={classes.cardVipPrice}>$100</h5>
                  <hr width={"80%"} />
                  <p className={classes.cardInfo}>
                    Tickets will be available at the gate for $15.
                    <br />
                    Pre-sale tickets available below.
                  </p>
                </CardBody>
                <CardFooter className={classes.justifyContentCenter} pricing>
                  <p>*Processing fees may apply</p>
                </CardFooter>
              </Card>
            </Slide>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  )
}
